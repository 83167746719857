@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* .scaleEleCircle */
@keyframes shimmer {
  from {
      background-position-x: 100%
  }
  to {
      background-position-x: 0%
  }
 
}
.shimmer{
      animation: shimmer 1.5s infinite linear;
      background: linear-gradient(-90deg, #F1EFEF 40%, #F9F8F8 50%, #E7E5E5 60%);
      background-size: 300%;
      background-position-x: 100%
   }