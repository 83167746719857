.custom-hotspot {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.hotspot {
    display: none !important;
}
#textInfo .hotspot .out {
    display: none !important;
}

.tooltip-content {
    background-color: red;
    color: white
}
  

.custom-hotspot-icon {
    width: 34px ; /* Adjust size as needed */
    height: 34px ; /* Adjust size as needed */
    background: url('https://spyne-static.s3.amazonaws.com/icons/feature.svg') !important;
    background-repeat: no-repeat !important;
    border: none !important;
    cursor: pointer !important; /* Indicate clickable hotspot */
    position: absolute !important;
    /* Additional styles if necessary */
  }


  .custom-hotspot-icon-tag {
    width: 34px ; /* Adjust size as needed */
    height: 34px ; /* Adjust size as needed */
    background: url('https://spyne-static.s3.amazonaws.com/icons/damage+(1).svg') !important;
    background-repeat: no-repeat !important;
    border: none !important;
    cursor: pointer !important; /* Indicate clickable hotspot */
    position: absolute !important;
    /* Additional styles if necessary */
  }

  
  .hidden-hotspot {
    display: none;
  }

  .tooltip {
    position: absolute;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    border-radius: 4px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    display: none;
    transition: opacity 0.2s;
  }
  
